import React from "react";
import styles from "./CustomSpinner.module.scss";

const CustomSpinner: React.FC<{ whiteColor?: boolean }> = (props) => {
  const { whiteColor } = props;

  return (
    <div
      className={`${styles.customSpinner} ${whiteColor ? styles.white : ""}`}
    >
      <div className={styles.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default CustomSpinner;
