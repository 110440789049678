import React, { useEffect, useRef } from "react"
import styles from "./DropdownContainer.module.scss"

interface propsI {
  onClickOutside?: (event: MouseEvent) => void
  className?: string
}

const DropdownContainer: React.FC<propsI> = (props) => {
  const dropdownContainerEl = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    // console.log(dropdownContainerEl.current?.contains(event.target as Node));
    // console.log(event.target);
    if (
      !dropdownContainerEl.current?.contains(event.target as Node) &&
      !(dropdownContainerEl.current === (event.target as Node))
    ) {
      if (props.onClickOutside) props.onClickOutside(event)
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClickOutside)
    return () => {
      window.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <div
      className={`${styles.specificityWrapper} ${
        props.className ? props.className : styles.defaultStyles
      } ${styles.dropdownContainer} `}
      ref={dropdownContainerEl}
    >
      {props.children}
    </div>
  )
}

export default DropdownContainer
