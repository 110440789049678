import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { unitTypeI } from "../../../../App";
import { useDefaultData } from "../../../../hooks/useDefaultData";
import CustomDateRangePicker from "../../../customDateRangePicker/CustomDateRangePicker";
import styles from "./CalendarDropdown.module.scss";
import mediaQueries from "../../../../styles/mediaQueries.module.scss";

const CalendarDropdown: React.FC<{
  type: unitTypeI;
}> = ({ type }) => {
  const { borderColor, startDate, setStartDate, endDate, setEndDate } =
    useDefaultData();

  const { t } = useTranslation();

  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const [selectedIniDate, setSelectedIniDate] = useState<moment.Moment | null>(
    startDate ? moment(startDate, "DD-MM-YYYY") : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(
    endDate ? moment(endDate, "DD-MM-YYYY") : null
  );
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >();

  const calendarDropdownEl = useRef<HTMLDivElement>(null);

  const handleClickDate = (focusedInput: "startDate" | "endDate" | null) => {
    setShowDateRangePicker(true);
    setFocusedInput(focusedInput);
  };

  const handleClickOutside = (e: any) => {
    if (!calendarDropdownEl.current?.contains(e.target as Node)) {
      setFocusedInput(undefined);
      setShowDateRangePicker(false);
    }
  };

  const handleClose = () => {
    setFocusedInput(undefined);
    setShowDateRangePicker(false);
  };

  const handleDateChange = (dateData: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    setSelectedIniDate(dateData.startDate);
    setSelectedEndDate(dateData.endDate);
    setStartDate(dateData.startDate?.format("DD-MM-YYYY") ?? null);
    setEndDate(dateData.endDate?.format("DD-MM-YYYY") ?? null);
  };

  useEffect(() => {
    if (startDate) setSelectedIniDate(moment(startDate, "DD-MM-YYYY"));
    if (endDate) setSelectedEndDate(moment(endDate, "DD-MM-YYYY"));
  }, [startDate, endDate]);

  return (
    <div className={`${styles.specificityWrapper} ${styles.mainContainer}`}>
      <div
        className={`${styles.calendarDropdown} ${
          type === "monounit" && styles.monounit
        }`}
        ref={calendarDropdownEl}
        style={{ borderColor: borderColor }}
      >
        <div className={styles.icon}>
          <img
            src="https://booking.availroom.com/v2/assets/icons/blue_calendar.svg"
            alt=""
          />
        </div>
        <div className={styles.calendarDropdownContainer}>
          <div
            className={styles.formGroup}
            onClick={() => {
              handleClickDate("startDate");
            }}
          >
            <span className={styles.label}>{t("from")}</span>
            <div className={`${styles.date}`}>
              {startDate
                ? moment(startDate, "DD-MM-YYYY").format("L")
                : t("start")}
            </div>
          </div>
          <div
            className={styles.formGroup}
            onClick={() => handleClickDate("endDate")}
          >
            <span className={styles.label}>{t("to")}</span>
            <div className={`${styles.date}`}>
              {endDate ? moment(endDate, "DD-MM-YYYY").format("L") : t("end")}
            </div>
          </div>
          {showDateRangePicker && (
            <CustomDateRangePicker
              className={`${styles.datePicker} ${
                type === "monounit" &&
                window.innerWidth > parseFloat(mediaQueries.mediumBreakpoint) &&
                styles.monounit
              }`}
              onClickOutside={handleClickOutside}
              focusedInput={focusedInput ?? "startDate"}
              onFocusChange={(focusedInput) => {
                setFocusedInput(focusedInput);
                if (!focusedInput) setShowDateRangePicker(false);
              }}
              startDate={selectedIniDate}
              endDate={selectedEndDate}
              onDateChange={handleDateChange}
              minDate={moment()}
              onClose={() => {
                handleClose();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarDropdown;
