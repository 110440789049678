import React, { useEffect, useState } from "react";
import styles from "./AgeInput.module.scss";

// icons
import colors from "../../../../../../styles/variables.module.scss";
import tinycolor from "tinycolor2";
import styled from "styled-components";
import DropdownContainer from "../../../../../dropdownContainer/DropdownContainer";
import { useDefaultData } from "../../../../../../hooks/useDefaultData";
import { useTranslation } from "react-i18next";

const agesUnder18 = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
];

interface propsI {
  onChange?: (value: number) => void;
  value?: number;
}

const List = styled.li`
  &:hover {
    background-color: ${(props: any) => props.mainColor ?? colors.primaryBlue};
    color: ${(props: any) =>
      tinycolor(props.mainColor).isDark() ? "#ffffff" : "#000000"};
  }
`;

const AgeInput: React.FC<propsI> = ({ onChange, value }) => {
  // redux hooks
  const { color, borderColor } = useDefaultData();

  const { t } = useTranslation();

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleSelect = (event: React.MouseEvent, age: number) => {
    // setSelectedAge(age);
    event.preventDefault();
    event.stopPropagation();
    setOpenDropdown(false);
    if (onChange) onChange(age);
  };

  return (
    <div className={`${styles.specificityWrapper} ${styles.AgeInputContainer}`}>
      <div
        className={styles.AgeInput}
        style={{
          ...(borderColor && { borderColor: borderColor }),
        }}
        onClick={handleOpenDropdown}
      >
        <div className={styles.leftContainer}>
          <span className={styles.label}>{value ?? 0}</span>
          <span className={styles.label}>{t("yearsOld")}</span>
        </div>
        <div className={styles.rightContainer}>
          <div
            className={`${styles.arrowBtn} ${openDropdown && styles.opened}`}
          >
            <img
              src="https://booking.availroom.com/v2/assets/icons/dropdown.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      {openDropdown && (
        <DropdownContainer onClickOutside={() => setOpenDropdown(false)}>
          <div className={styles.dropdownContainer}>
            <ul>
              {agesUnder18.map((age) => (
                <List
                  mainColor={color}
                  key={age}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                    handleSelect(event, age)
                  }
                >
                  {age}
                </List>
              ))}
            </ul>
          </div>
        </DropdownContainer>
      )}
    </div>
  );
};

export default AgeInput;
