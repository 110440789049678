import moment from "moment";
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import "react-dates/initialize";
import { DayPickerRangeController } from "react-dates";
import mediaQueries from "../../styles/mediaQueries.module.scss";
import tinycolor from "tinycolor2";
import { useTranslation } from "react-i18next";
import { useDefaultData } from "../../hooks/useDefaultData";
import colors from "../../styles/variables.module.scss";
import CustomButton from "../customButton/CustomButton";
import customStyles from "./CustomDateRangePicker.module.scss";
import "react-dates/lib/css/_datepicker.css";
import styled from "styled-components";

interface propsI {
  availableDates?: string[];
  unavailableDates?: string[];
  manually?: boolean;
  onDateChange: (data: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => void;
  className?: string;
  onClickOutside?: (e: MouseEvent) => void;
  focusedInput?: "startDate" | "endDate" | null;
  onFocusChange?: (focusedInput: "startDate" | "endDate" | null) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  orientation?: "vertical" | "verticalScrollable" | "horizontal";
  allowSingleDate?: boolean;
  onClose?: () => void;
}

const CustomDateRangePicker: React.FC<propsI> = ({
  availableDates,
  unavailableDates,
  onDateChange,
  onClickOutside,
  allowSingleDate,
  manually,
  orientation,
  onClose,
  ...props
}) => {
  // redux hooks
  const { color } = useDefaultData();

  // libraryHooks
  const { t } = useTranslation();

  const [focusedInput, setFocuseInput]: any = useState<"startDate" | "endDate">(
    props.focusedInput ?? "startDate"
  );
  const [startDate, setStartDate]: any = useState(props.startDate);
  const [endDate, setEndDate]: any = useState(props.endDate);

  const availableMoments = useRef(
    availableDates?.map((availableDate) => moment(availableDate, "DD-MM-YYYY"))
  );

  const primaryColor = color ? tinycolor(color) : tinycolor(colors.primaryBlue);

  const secondaryColor = primaryColor.isDark()
    ? primaryColor.clone().lighten(20)
    : primaryColor.clone().darken(20);

  const handleDateChange = (data: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    let newStartDate = data.startDate?.clone() ?? null;
    let newEndDate = data.endDate?.clone() ?? null;
    if (startDate && endDate) {
      newStartDate = data.startDate ?? data.endDate;
      newEndDate = null;
    }
    if (
      newEndDate?.isBefore(newStartDate, "day") ||
      newEndDate?.isSame(newStartDate, "day")
    ) {
      newEndDate = newStartDate?.clone().add(1, "day") ?? null;
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onDateChange({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  useEffect(() => {
    if (startDate && endDate) {
      props.onFocusChange?.("startDate");
      return;
    }
  }, [startDate, endDate, props]);

  const isDayBlocked = (day: moment.Moment) => {
    if (!unavailableDates?.length) return false;
    return unavailableDates?.some((unavailableDate) =>
      day.isSame(moment(unavailableDate, "DD-MM-YYYY"), "days")
    );
  };

  const getMinDate = () => {
    if (props.minDate) return props.minDate;
    if (props.minDate) return props.minDate;
    if (availableDates?.length) return moment.min(availableMoments.current!);
    return null;
  };

  const getMaxDate = () => {
    if (props.maxDate) return props.maxDate;
    if (!availableDates?.length) return undefined;
    return moment.max(availableMoments.current!);
  };

  const getOutsideRange = (day: moment.Moment) => {
    // return false;
    if (availableDates?.length) return day.isBefore(moment(), "day");
    if (props.minDate) return day.isBefore(props.minDate, "day");
    if (props.maxDate) return day.isAfter(props.maxDate, "day");
    return false;
  };

  const getOrientation = () => {
    if (orientation) {
      // setCurrentOrientation(orientation);
      return orientation;
    }
    if (window.innerWidth < parseFloat(mediaQueries.smallBreakpoint)) {
      // setCurrentOrientation("vertical");
      return "vertical";
    }
    // setCurrentOrientation("horizontal");
    return "horizontal";
  };

  const colorCustomStyles = `
      .CalendarDay__selected_span:active,
      .CalendarDay__selected_span:hover {
        background: ${secondaryColor
          .clone()
          .lighten(15)
          .toRgbString()} !important;
        border: 1px double ${secondaryColor
          .clone()
          .lighten(15)
          .toRgbString()} !important;
        color: ${
          secondaryColor.clone().lighten(15).isDark() ? "#fff" : "#000"
        } !important;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: ${primaryColor.toHexString()} !important;
        border: 1px double ${primaryColor.toHexString()} !important;
      }

      .CalendarDay__hovered_span,
      .CalendarDay__hovered_span:hover {
        background: ${secondaryColor
          .clone()
          .setAlpha(0.5)
          .toRgbString()} !important;
        border: 1px double ${primaryColor.toHexString()} !important;
        color: ${
          secondaryColor.setAlpha(0.5).isDark() ? "#fff" : "#000"
        } !important;
      }
      .CalendarDay__hovered_span:active {
        background: #c8e1f8 !important;
        border: 1px double ${primaryColor.toHexString()} !important;
        color: #003687 !important;
      }

      .CalendarDay__selected_span {
        background: ${secondaryColor.toHexString()} !important;
        border: 1px double ${secondaryColor.toHexString()} !important;
        color: #fff !important;
      }
    `;

  return (
    <div
      className={`${customStyles.specificityWrapper} ${
        props.className ? props.className : "dateRangePickerContainer"
      } ${customStyles.app} `}
    >
      <style>{colorCustomStyles}</style>
      <DayPickerRangeController
        isDayHighlighted={(date: moment.Moment) =>
          !!availableDates?.includes(date.format("DD-MM-YYYY"))
        }
        minimumNights={0}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        isOutsideRange={getOutsideRange}
        orientation={getOrientation()}
        startDate={startDate} // momentPropTypes.momentObj or null,
        endDate={endDate} // momentPropTypes.momentObj or null,
        onDatesChange={({ startDate, endDate }: any) => {
          handleDateChange({ startDate, endDate });
        }}
        focusedInput={
          props.focusedInput !== undefined ? props.focusedInput : focusedInput
        } // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput: any) => {
          setFocuseInput(focusedInput);
          props.onFocusChange?.(focusedInput);
        }} // PropTypes.func.isRequired,
        initialVisibleMonth={props.startDate ? () => props.startDate! : null} // PropTypes.func or null,
        numberOfMonths={getOrientation() !== "horizontal" ? 1 : 2}
        onOutsideClick={onClickOutside as any}
        {...(availableDates && {
          isDayBlocked: (day: any) => {
            return isDayBlocked(day);
          },
        })}
        renderCalendarInfo={() => (
          <>
            {availableDates && (
              <div className="datePickerInfo">
                <div className="greenCircleDatePicker"></div>
                {t("labels.daysAvailable")}
              </div>
            )}{" "}
            {onClose && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  {...(!(getOrientation() === "horizontal")
                    ? {
                        style: {
                          position: "absolute",
                          bottom: "calc(52px + 0.5em)",
                          left: "50%",
                          transform: "translate(-50%,0)",
                        },
                      }
                    : {
                        style: {
                          display: "inline-block",
                          // marginBottom: "1em",
                          margin: "0 auto 1em",
                        },
                      })}
                  onClick={() => {
                    if (onClose) onClose();
                  }}
                >
                  <CustomButton>{t("accept")}</CustomButton>
                </div>
              </div>
            )}
          </>
        )}
        hideKeyboardShortcutsPanel={true}
        {...(!(getOrientation() === "vertical") && {
          keepOpenOnDateSelect: true,
        })}
        // keepOpenOnDateSelect={()=>(CurrentOrientation === "vertical" || false)}
      />
    </div>
  );
};

export default CustomDateRangePicker;
