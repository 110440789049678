import { AxiosResponse } from "axios";
import i18next from "i18next";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DEFAULT_ADULTS } from "../defaultVars";
import authAxios from "../utils/authAxios";
import useLanguage from "./useLanguage";
import useQuery from "./useQuery";

export interface ZoneI {
  id: string;
  name: string;
  hotelDataList?: hotelZoneI[];
}

interface hotelZoneI {
  id: string;
  name: string;
}

interface ChildrenObjectI {
  id: string;
  age: number;
}

interface DefaultDataContextI {
  fetchDefaultData: (
    language: string | null,
    environmentId: string
  ) => Promise<void>;
  fetchUbicationFilterList: (
    serverPath: string,
    environmentId: string
  ) => Promise<void>;
  loading: boolean;
  error: string;
  serverPath: string;
  ubicationFilterList: ZoneI[];
  borderColor: string;
  color: string;
  ubication: ZoneI;
  setUbication: React.Dispatch<React.SetStateAction<ZoneI>>;
  startDate: string | null;
  setStartDate: React.Dispatch<React.SetStateAction<string | null>>;
  endDate: string | null;
  setEndDate: React.Dispatch<React.SetStateAction<string | null>>;
  childrensObject: ChildrenObjectI[];
  setChildrensObject: React.Dispatch<React.SetStateAction<ChildrenObjectI[]>>;
  childrens: number;
  setChildrens: React.Dispatch<React.SetStateAction<number>>;
  adults: number;
  setAdults: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: () => void;
  defaultLanguage: string;
  type: "monounit" | "multiunit";
}

const DefaultDataContext = createContext({} as DefaultDataContextI);

export const DefaultDataContextProvider: React.FC<{
  relativePath: string;
  defaultLanguage: string;
  type: "monounit" | "multiunit";
}> = (props) => {
  const { children, relativePath, defaultLanguage, type } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQuery();
  const [language] = useLanguage();

  const [ubication, setUbication] = useState<ZoneI>({
    name: t("all_locations"),
    id: "",
  });
  const [childrensObject, setChildrensObject] = useState<
    Array<ChildrenObjectI>
  >([]);
  const [ubicationFilterList, setUbicationFilterList] = useState<Array<ZoneI>>(
    []
  );
  const [startDate, setStartDate] = useState<string | null>(
    moment().format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState<string | null>(
    moment().add(2, "days").format("DD-MM-YYYY")
  );
  const [borderColor, setBorderColor] = useState("");
  const [serverPath, setServerPath] = useState("");
  const [adults, setAdults] = useState<number>(DEFAULT_ADULTS);
  const [childrens, setChildrens] = useState(0);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    i18next.changeLanguage(language);
    setUbication({
      name: t("all_locations"),
      id: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDefaultData = async (
    language: string | null,
    environmentId: string
  ) => {
    setLoading(true);
    try {
      const response = await authAxios.post(
        "https://api.availroom.com/sialdPMS_Channel/service/resourcescrossorigin/webcrossorigin/getbookingenginedefaultdata",
        {
          userLang: language,
          environmentDataId: environmentId,
        }
      );
      console.log("Default data response", response.data);
      setServerPath(response.data.clientDataList[0].serverPath);
      setBorderColor(response.data.clientDataList[0].borderColor);
      setColor(response.data.clientDataList[0].color);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchUbicationFilterList = async (
    serverPath: string,
    environmentId: string
  ) => {
    if (!serverPath) return;
    try {
      setLoading(true);
      const response: AxiosResponse<{
        ok: boolean;
        zoneDataList: ZoneI[];
        hotelDataList: hotelZoneI[];
      }> = await authAxios.post(
        `https://${serverPath}/sialdPMS/web/resourcescrossorigin/webcrossorigin/getfindzoneortag_api`,
        {
          environmentDataId: environmentId,
        }
      );
      console.log("Ubication Filter data response", response.data);
      if (response.data.ok) {
        let newUbicationFilterList = [{ id: "", name: t("all_locations") }];
        if (response.data.zoneDataList) {
          newUbicationFilterList = [
            ...newUbicationFilterList,
            ...response.data.zoneDataList,
          ];
        }
        if (response.data.hotelDataList) {
          newUbicationFilterList = [
            ...newUbicationFilterList,
            ...response.data.hotelDataList,
          ];
        }
        setUbicationFilterList(newUbicationFilterList);
      }
    } catch (error: any) {
      console.log("eeeeee", error);
      setError(error);
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    console.log({
      ubication,
      startDate,
      endDate,
      childrensObject,
      childrens,
      adults,
    });

    const childrenAgeList = childrensObject.map((child) => child.age);

    let queryArray: { id: string; value: string }[] = [];
    if (history.location.search) {
      queryArray = history.location.search.split("&").map((query) => {
        let currentQuery = query;
        if (query.startsWith("?")) {
          currentQuery = currentQuery.substring(1);
        }
        const a = currentQuery.split("=");
        return { id: a[0], value: a[1] };
      });
    }
    if (adults) {
      const AdultsQueryId = queryArray.findIndex(
        (query) => query.id === "adults"
      );
      if (AdultsQueryId !== -1) {
        queryArray[AdultsQueryId].value = `${adults}`;
      } else {
        queryArray.push({
          id: "adults",
          value: `${adults}`,
        });
      }
    }
    if (startDate) {
      const iniDateQueryId = queryArray.findIndex(
        (query) => query.id === "iniDate"
      );
      if (iniDateQueryId !== -1) {
        queryArray[iniDateQueryId].value = startDate;
      } else {
        queryArray.push({
          id: "iniDate",
          value: startDate,
        });
      }
    }
    if (endDate) {
      const endDateQueryId = queryArray.findIndex(
        (query) => query.id === "endDate"
      );
      if (endDateQueryId !== -1) {
        queryArray[endDateQueryId].value = endDate;
      } else {
        queryArray.push({
          id: "endDate",
          value: endDate,
        });
      }
    }
    if (childrenAgeList?.length) {
      const childrenAgeListQueryId = queryArray.findIndex(
        (query) => query.id === "childrenAges"
      );
      if (childrenAgeListQueryId !== -1) {
        queryArray[childrenAgeListQueryId].value = childrenAgeList.join(",");
      } else {
        queryArray.push({
          id: "childrenAges",
          value: childrenAgeList.join(","),
        });
      }
    }
    if (ubication.id !== undefined) {
      const zoneIdQueryId = queryArray.findIndex(
        (query) => query.id === "zoneId"
      );
      if (zoneIdQueryId !== -1) {
        queryArray[zoneIdQueryId].value = ubication.id;
      } else {
        queryArray.push({
          id: "zoneId",
          value: ubication.id,
        });
      }
    }

    if (defaultLanguage) {
      const languageQueryId = queryArray.findIndex(
        (query) => query.id === "language"
      );
      const language = queryParams.get("language");

      if (languageQueryId !== -1) {
        queryArray[languageQueryId].value =
          (language === "en" ? "en_uk" : language) ??
          (defaultLanguage === "en" ? "en_uk" : defaultLanguage);
      } else {
        queryArray.push({
          id: "language",
          value:
            (language === "en" ? "en_uk" : language) ??
            (defaultLanguage === "en" ? "en_uk" : defaultLanguage),
        });
      }
    }

    const newParams = queryArray.reduce((acum, currentValue, currentIndex) => {
      acum += `${currentIndex === 0 ? "" : "&"}${currentValue.id}=${
        currentValue.value
      }`;
      return acum;
    }, "");

    window.location.href = `${relativePath}${
      relativePath.includes("?") ? "&" : "?"
    }${newParams}`;
  };

  return (
    <DefaultDataContext.Provider
      value={{
        fetchUbicationFilterList,
        ubicationFilterList,
        fetchDefaultData,
        borderColor,
        serverPath,
        loading,
        error,
        color,
        ubication,
        setUbication,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        childrensObject,
        setChildrensObject,
        childrens,
        setChildrens,
        adults,
        setAdults,
        handleSubmit,
        defaultLanguage,
        type,
      }}
    >
      {children}
    </DefaultDataContext.Provider>
  );
};

export const useDefaultData = () => useContext(DefaultDataContext);
