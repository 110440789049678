import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDefaultData } from "../../../../hooks/useDefaultData";
import styles from "./TravelersDropdown.module.scss";
import { v4 } from "uuid";
import DropdownContainer from "../../../dropdownContainer/DropdownContainer";
import colors from "../../../../styles/variables.module.scss";
import styled from "styled-components";
import NumberInput from "./components/numberInput/NumberInput";
import AgeInput from "./components/ageInput/AgeInput";
import { DEFAULT_CHILDREN_AGE } from "../../../../defaultVars";

const CustomDropdown = styled.div`
  width: 100%;
`;

const TravelersDropdown: React.FC = (props) => {
  const { children } = props;

  const {
    borderColor,
    childrensObject,
    setChildrensObject,
    childrens,
    setChildrens,
    adults,
    setAdults,
  } = useDefaultData();

  const { t } = useTranslation();

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleChangeChildrenQuantity = (value: number) => {
    let newChildrensObj: Array<{ id: string; age: number }> = [];

    if (value > childrensObject.length) {
      newChildrensObj = [
        ...childrensObject,
        { id: v4(), age: DEFAULT_CHILDREN_AGE },
      ];
    }

    if (value < childrensObject.length) {
      newChildrensObj = childrensObject.slice(0, childrensObject.length - 1);
    }

    if (value === childrensObject.length) return;

    setChildrensObject(newChildrensObj);
    setChildrens(value);
  };

  const handleAdultChange = (value: number) => {
    setAdults(value);
  };

  const handleChangeAge = (age: number, id: string) => {
    const childrenToChange = childrensObject.findIndex(
      (childrenObject) => childrenObject.id === id
    );
    const newChildrensObj = [...childrensObject];
    newChildrensObj[childrenToChange] = { id: id, age: age };
    setChildrensObject(newChildrensObj);
  };

  return (
    <div
      className={` ${styles.specificityWrapper} ${
        !children ? styles.travelersDropdown : styles.travelersDropdownCustom
      }`}
    >
      {children ? (
        <CustomDropdown onClick={() => setOpenDropdown(!openDropdown)}>
          {children}
        </CustomDropdown>
      ) : (
        <div
          className={`${styles.travelersDropdownBox}`}
          onClick={() => setOpenDropdown(!openDropdown)}
          style={{ borderColor: borderColor || colors.borderLightgray }}
        >
          <div className={styles.icon}>
            <img
              src="https://booking.availroom.com/v2/assets/icons/user.svg"
              alt=""
            />
          </div>
          <div className={styles.container}>
            <div
              className={styles.label}
              style={{ color: colors.textLightgray }}
            >
              {t("travellers")}
            </div>
            <div className={styles.selectedValue}>{`${adults} ${t("adults")}${
              +childrens > 0 ? ` ${childrens} ${t("children")}` : ""
            }`}</div>
          </div>
          <div
            className={`${styles.dropdownIcon} ${
              openDropdown && styles.dropdownIconOpen
            }`}
          >
            <img
              src="https://booking.availroom.com/v2/assets/icons/dropdown.svg"
              alt=""
            />
          </div>
        </div>
      )}
      <div>
        {openDropdown && (
          <DropdownContainer
            className={!children ? "" : styles.extended}
            onClickOutside={() => setOpenDropdown(false)}
          >
            <div className={styles.dropdownContainer}>
              <div className={styles.inputContainer}>
                <NumberInput
                  startAt={adults}
                  min={1}
                  max={25}
                  label={t("adults")}
                  onChange={handleAdultChange}
                />
              </div>
              <div className={styles.inputContainer}>
                <NumberInput
                  startAt={+childrens}
                  min={0}
                  max={25}
                  label={t("children")}
                  onChange={handleChangeChildrenQuantity}
                />
              </div>
              <div className={styles.childrenAgesContainer}>
                {childrensObject.length > 0 && <p>{t("childrenAges")}</p>}
                {childrensObject.map((childrenObject) => (
                  <div
                    className={styles.inputContainer}
                    key={childrenObject.id}
                  >
                    <AgeInput
                      value={childrenObject.age}
                      onChange={(age) =>
                        handleChangeAge(age, childrenObject.id)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </DropdownContainer>
        )}
      </div>
    </div>
  );
};

export default TravelersDropdown;
