import React, { useEffect, useState } from "react"
import styles from "./NumberInput.module.scss"

// icons
import { BsPlusCircle, BsDashCircle } from "react-icons/bs"
import { useDefaultData } from "../../../../../../hooks/useDefaultData"

interface propsI {
  label: string
  startAt?: number
  min?: number
  max?: number
  onChange?: (value: number) => void
}

const NumberInput: React.FC<propsI> = ({
  label,
  startAt,
  min,
  max,
  onChange,
  ...props
}) => {
  // redux hooks
  const { borderColor } = useDefaultData()
  const [value, setValue] = useState(startAt ?? 0)
  const incrementValue = () => {
    setValue((prevValue) => {
      if (max !== undefined && prevValue + 1 > max) {
        return prevValue
      }
      return prevValue + 1
    })
  }
  const decrementValue = () => {
    setValue((prevValue) => {
      if (min !== undefined && prevValue - 1 < min) {
        return prevValue
      }
      return prevValue - 1
    })
  }
  useEffect(() => {
    if (onChange !== undefined) onChange(value)
  }, [value])
  return (
    <div
      className={`${styles.specificityWrapper} ${styles.NumberInputContainer}`}
    >
      <div
        className={styles.NumberInput}
        style={{
          ...(borderColor && { borderColor: borderColor })
        }}
      >
        <div className={styles.leftContainer}>
          <span className={styles.label}>{label}</span>
          <span className={styles.number}>{value}</span>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.btnContainer}>
            <div
              className={`${styles.btn} ${
                min !== undefined && value - 1 < min ? styles.disabled : ""
              }`}
              onClick={decrementValue}
            >
              <BsDashCircle />
            </div>
            <div
              className={`${styles.btn} ${
                max !== undefined && value + 1 > max ? styles.disabled : ""
              }`}
              onClick={incrementValue}
            >
              <BsPlusCircle />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NumberInput
