import React from "react";
import styles from "./LocationDropdown.module.scss";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import colors from "../../../../styles/variables.module.scss";
import { useDefaultData, ZoneI } from "../../../../hooks/useDefaultData";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownContainer from "../../../dropdownContainer/DropdownContainer";

const List = styled.li`
  &:hover {
    background-color: ${(props: any) => props.mainColor ?? colors.primaryBlue};
    color: ${(props: any) =>
      tinycolor(props.mainColor).isDark() ? "#ffffff" : "#000000"};
  }
`;

const LocationDropdown: React.FC = () => {
  const { color, borderColor, ubicationFilterList, ubication, setUbication } =
    useDefaultData();

  const { t } = useTranslation();

  const [openDropdown, setOpenDropdown] = useState(false);

  const renderLists = (filterList: ZoneI[]) => {
    let list: Array<
      ZoneI & {
        type: "zone" | "hotel";
      }
    > = [];
    filterList.map((filter) => {
      if (!filter.hotelDataList?.length) {
        list.push({ ...filter, type: "zone" });
      } else {
        list.push({ ...filter, type: "zone" });
        filter.hotelDataList.map((hotelFilter) => {
          list.push({ ...hotelFilter, type: "hotel" });
        });
      }
    });
    return list;
  };

  return (
    <div className={`${styles.specificityWrapper} ${styles.locationDropdown}`}>
      <div
        className={`${styles.locationDropdownBox}`}
        onClick={() => setOpenDropdown(!openDropdown)}
        style={{ borderColor: borderColor || colors.borderLightgray }}
      >
        <div className={styles.icon}>
          <img
            src="https://booking.availroom.com/v2/assets/icons/search.svg"
            alt=""
          />
        </div>
        <div className={styles.container}>
          <div
            className={styles.label}
            style={{
              color: colors.textLightgray,
            }}
          >
            {t("selected_locations")}
          </div>
          <div className={styles.selectedValue}>{ubication?.name}</div>
        </div>
        <div
          className={`${styles.dropdownIcon} ${
            openDropdown && styles.dropdownIconOpen
          }`}
        >
          <img
            src="https://booking.availroom.com/v2/assets/icons/dropdown.svg"
            alt=""
          />
        </div>
      </div>
      <div>
        {openDropdown && (
          <DropdownContainer onClickOutside={() => setOpenDropdown(false)}>
            <div className={styles.dropdownSelectContainer}>
              <ul>
                {ubicationFilterList &&
                  renderLists(ubicationFilterList).map((ubicationFilter) => (
                    <List
                      key={ubicationFilter.id}
                      onClick={() => {
                        setUbication({
                          id: ubicationFilter.id,
                          name: ubicationFilter.name,
                        });
                        setOpenDropdown(false);
                      }}
                      className={`${
                        ubicationFilter.type === "hotel"
                          ? styles.hotelOption
                          : ""
                      }`}
                      mainColor={color}
                    >
                      {ubicationFilter.name}
                    </List>
                  ))}
              </ul>
            </div>
          </DropdownContainer>
        )}
      </div>
    </div>
  );
};

export default LocationDropdown;
