import useQuery from "./useQuery";
import { useDefaultData } from "./useDefaultData";

function languageFormatter(language: string): string {
  return language === "en_us" || language === "en_uk" ? "en" : language;
}

function momentLanguageFormatter(language: string): string {
  return language === "en_uk" || language === "en" ? "en-gb" : language;
}

export default function useLanguage() {
  const queryParams = useQuery();
  const { defaultLanguage } = useDefaultData();

  let language = "";

  const queryLanguage = queryParams.get("language");

  if (defaultLanguage) {
    language = defaultLanguage;
  }

  if (queryLanguage) {
    language = queryLanguage;
  }

  return [languageFormatter(language), momentLanguageFormatter(language)];
}
