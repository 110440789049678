import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import App, { unitTypeI } from "./App";
import CustomSpinner from "./components/customSpinner/CustomSpinner";
import { BrowserRouter, Route } from "react-router-dom";
import { DefaultDataContextProvider } from "./hooks/useDefaultData";
import "./index.css";
import "./i18n";

declare global {
  interface Window {
    availRoomBookingWidget: any;
  }
}

export interface propsConfigI {
  environment: string;
  loading?: boolean;
  baseLanguage?: string;
}

class availRoomBookingWidget {
  constructor(propsConfig: {
    divId: string;
    environmentId: string;
    domainUrl: string;
    language: string;
    type: unitTypeI;
  }) {
    const { divId, environmentId, domainUrl, language, type } = propsConfig;

    ReactDOM.render(
      <React.StrictMode>
        <Suspense
          fallback={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomSpinner />
            </div>
          }
        >
          <BrowserRouter>
            <Route path="/">
              <DefaultDataContextProvider
                relativePath={domainUrl}
                defaultLanguage={language}
                type={type}
              >
                <App environment={environmentId} baseLanguage={language} />
              </DefaultDataContextProvider>
            </Route>
          </BrowserRouter>
        </Suspense>
      </React.StrictMode>,
      document.getElementById(divId)
    );
  }
}

export default availRoomBookingWidget;
